import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../../components/Layout';
import { getSeoConfig } from '../../../constants/defaultSeoProps';
import ConsumerImg from '../../../assets/ai/ConsumerTouchPoints.png';
import OperationalImg from '../../../assets/ai/OperationalEfficiency.png';
import EnterpriseImg from '../../../assets/ai/EnterpriseApplications.png';
import OfferingsImg from '../../../assets/ai/OtherOfferings.png';

import s from './GenAI.module.scss';

const propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contactUsContent: PropTypes.string.isRequired,
    genAISections: PropTypes.array.isRequired,
    contactUsSummary: PropTypes.string.isRequired,
  }).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/genAI.png',
  ogTitle: 'AI and Gen AI Services at Tech Holding',
  ogDesc:
    'Utilize AI-driven insights to create personalized customer experiences across various digital touchpoints.',
  ogPath: '/services/ai',
};

const updatedSeo = getSeoConfig(seoParams);

const GenAI = ({ details, companyQuote }) => {
  const { title } = details;
  return (
    <Layout
      currentPage="/services/ai"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <div className={s.bgGray}>
        <div className={s.headerContainer} data-test="headerContainer">
          <div className={s.headerContent}>
            <div className={s.title}>
              <h2 data-test="title">{title}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className={s.pageContainer} data-test="pageContainer">
        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <div className={s.image}>
            <img className={s.emoji} src={ConsumerImg} alt="" />
          </div>
          <h2>Consumer Touch Points</h2>
          <div className={s.flex}>
            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>Personalized Customer Experiences</h3>
                <ul>
                  <li>
                    Utilize AI-driven insights to create personalized customer experiences across
                    various digital touchpoints.
                  </li>
                  <li>
                    Implement recommendation systems, personalized marketing campaigns, and customer
                    journey mapping using Gen AI.
                  </li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>Sentiment Analysis</h3>
                <ul>
                  <li>
                    Implement sentiment analysis tools to monitor and analyze customer feedback in
                    real-time.
                  </li>
                  <li>
                    Use AI to gauge customer sentiments across social media, reviews, and other
                    platforms to inform business strategies.
                  </li>
                </ul>
              </div>
            </div>

            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>Chatbots and Virtual Assistants</h3>
                <ul>
                  <li>
                    Develop and deploy intelligent chatbots and virtual assistants for customer
                    support and engagement.
                  </li>
                  <li>
                    Integrate natural language processing (NLP) to enhance interaction quality and
                    user satisfaction.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <div className={s.image}>
            <img className={s.emoji} src={OperationalImg} alt="" />
          </div>
          <h2>Operational Efficiency</h2>
          <div className={s.flex}>
            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>Predictive Maintenance</h3>
                <ul>
                  <li>
                    Leverage AI to predict equipment failures and maintenance needs, reducing
                    downtime and operational costs.
                  </li>
                  <li>
                    Implement machine learning models to analyze historical data and forecast
                    potential issues.
                  </li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>Process Automation</h3>
                <ul>
                  <li>
                    Implement robotic process automation (RPA) powered by AI to automate repetitive
                    tasks and streamline business processes.
                  </li>
                  <li>
                    Enhance operational efficiency and reduce human error through intelligent
                    automation solutions.
                  </li>
                </ul>
              </div>
            </div>

            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>Scheduling</h3>
                <ul>
                  <li>Enhance worker and resource scheduling through AI-driven optimization.</li>
                  <li>
                    Use Gen AI to model complex scheduling scenarios, improving efficiency and
                    productivity.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <div className={s.image}>
            <img className={s.emoji} src={EnterpriseImg} alt="" />
          </div>
          <h2>Enterprise Applications</h2>
          <div className={s.flex}>
            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>AI-Powered Analytics</h3>
                <ul>
                  <li>
                    Develop and implement advanced analytics solutions using AI to uncover
                    actionable insights from enterprise data.
                  </li>
                  <li>
                    Use Gen AI to enhance data visualization, predictive modeling, and decision
                    support systems.
                  </li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>Data Integration and Management</h3>
                <ul>
                  <li>
                    Utilize our expertise with AWS, GCP, and Snowflake to provide robust data
                    integration and management solutions.
                  </li>
                  <li>
                    Implement data lakes, warehouses, and ETL processes to ensure seamless data flow
                    and accessibility.
                  </li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>AI Agents</h3>
                <ul>
                  <li>
                    Develop and deploy AI agents to automate complex tasks and decision-making
                    processes.
                  </li>
                  <li>
                    Utilize AI agents for customer service, data analysis, and operational
                    management.
                  </li>
                </ul>
              </div>
            </div>

            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>Custom AI Solutions</h3>
                <ul>
                  <li>
                    Design and develop bespoke AI applications tailored to specific enterprise needs
                    and challenges.
                  </li>
                  <li>
                    Integrate AI capabilities into existing enterprise systems for enhanced
                    functionality and performance.
                  </li>
                </ul>
              </div>
              <div className={s.blockDetails}>
                <h3>Retrieval-Augmented Generation (RAGs)</h3>
                <ul>
                  <li>
                    Implement RAGs to enhance content generation by combining AI with relevant data
                    retrieval.
                  </li>
                  <li>
                    Improve the accuracy and relevance of generated content for various business
                    applications.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <div className={s.image}>
            <img className={s.emoji} src={OfferingsImg} alt="" />
          </div>
          <h2>Other Offerings</h2>
          <div className={s.flex}>
            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>AI Strategy and Consulting</h3>
                <ul>
                  <li>
                    Offer comprehensive consulting services to help organizations develop and
                    implement their AI strategy.
                  </li>
                  <li>
                    Provide expert guidance on AI adoption, technology selection, and roadmap
                    development.
                  </li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>Innovation and R&D</h3>
                <ul>
                  <li>
                    Collaborate on research and development projects to explore new AI and Gen AI
                    applications.
                  </li>
                  <li>
                    Partner with our experts to innovate and develop cutting-edge solutions that
                    address unique business challenges.
                  </li>
                </ul>
              </div>

              <div className={s.blockDetails}>
                <h3>Custom Model Training</h3>
                <ul>
                  <li>
                    Provide services to train custom AI models tailored to your specific business
                    needs.
                  </li>
                  <li>
                    Use advanced techniques and frameworks to develop models that deliver precise
                    and actionable insights.
                  </li>
                </ul>
              </div>
            </div>

            <div className={s.column}>
              <div className={s.blockDetails}>
                <h3>AI and Gen AI Training</h3>
                <ul>
                  <li>
                    Conduct training programs and workshops to equip your team with the necessary
                    skills and knowledge in AI and Gen AI.
                  </li>
                  <li>
                    Offer tailored training sessions to meet the specific needs of your
                    organization.
                  </li>
                </ul>
              </div>
              <div className={s.blockDetails}>
                <h3>AWS BedRock and OpenAI Integrations</h3>
                <ul>
                  <li>
                    Leverage AWS BedRock, OpenAI and other leading technologies to build and deploy
                    state-of-the-art AI models.
                  </li>
                  <li>
                    Integrate these platforms to enhance the capabilities of your AI solutions and
                    deliver superior performance.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={s.block} data-aos="fade-up" data-aos-delay="400">
          <h3>Partnered with Leading Technology Providers</h3>
          <ul>
            <li>
              Leverage our partnerships with industry-leading technology providers to deliver
              scalable, secure, and high-performance AI solutions.
            </li>
            <li>
              Utilize AWS, GCP, and Snowflake platforms to enhance data processing, storage, and
              analytics capabilities.
            </li>
          </ul>
          <p>
            By choosing Tech Holding’s AI and Gen AI services, you are partnering with a leader in
            technology consulting that is committed to driving innovation, enhancing operational
            efficiency, and delivering exceptional value across various industries. Contact us today
            to learn more about how we can help your organization harness the power of AI and Gen
            AI.
          </p>
        </div>
      </div>
    </Layout>
  );
};

GenAI.propTypes = propTypes;
export default GenAI;
